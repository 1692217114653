<template>
  <div class="hero master-w">
    <div
      v-if="addNewService"
      id="newServicePopup"
      v-bind:class="{ mobile: isMobile() }"
    >
      <div class="popup-card">
        <p class="title">Nouveau Service</p>
        <b-field label="Nom" label-position="on-border">
          <b-input
            size="is-large"
            type="text"
            v-model="newService.name"
          ></b-input>
        </b-field>
        <b-field label="Prix" label-position="on-border">
          <b-input
            size="is-large"
            type="number"
            v-model="newService.price"
          ></b-input>
        </b-field>
        <b-field label="Description" label-position="on-border">
          <b-input type="textarea" v-model="newService.desc"></b-input>
        </b-field>
        <b-button
          @click="sendNewService()"
          :disabled="!validateForm"
          expanded
          size="is-large"
          :type="{ 'is-success': validateForm }"
          class="validateBtn"
          >Ajouter</b-button
        >
        <b-button
          @click="togglePopup()"
          expanded
          size="is-large"
          type="is-danger"
          class="closeBtn"
          >Annuler</b-button
        >
      </div>
    </div>
    <div
      v-if="addNewType"
      id="typesPopup"
      v-bind:class="{ mobile: isMobile() }"
    >
      <div class="popup-card">
        <p class="title">Catégories</p>
        <div>
          <div
            v-for="type of typesList.servicesTypes"
            :key="type"
            class="type-w"
          >
            <p class="subtitle">{{ type }}</p>
            <b-button
              @click="deleteType(type)"
              size="is-medium"
              type="is-danger"
              class="closeBtn"
              >Supprimer</b-button
            >
          </div>
        </div>

        <div v-if="showNewTypeInput" class="name-form-w">
          <b-field label="Categorie" label-position="on-border">
            <b-input
              size="is-large"
              type="text"
              v-model="newTypeValue"
            ></b-input>
          </b-field>
          <div class="btns">
            <button v-on:click="pushType()" class="button is-success">
              Valider
            </button>
            <button v-on:click="toggleNewTypeInput()" class="button is-danger">
              Annuler
            </button>
          </div>
        </div>
        <b-button
          v-if="!showNewTypeInput"
          @click="toggleNewTypeInput()"
          expanded
          size="is-large"
          type="is-success"
          class="closeBtn"
          >Nouvelle categorie</b-button
        >
        <b-button
          @click="togglePopupTypes()"
          expanded
          size="is-large"
          type="is-danger"
          class="closeBtn"
          >Fermer</b-button
        >
        <p class="danger-text"><span>attention</span><br><br>quand on supprime une catégorie, il faut ensuite bien verifier que tous les soins sont affectés à une catégorie</p>
      </div>
    </div>
    <div class="hero-body">
      <div class="title-w" v-bind:class="{ mobile: isMobile() }">
        <p class="title">MODIFIER LE SPA</p>
        <button
          v-on:click="togglePopup()"
          class="button is-primary is-medium addBtn"
          v-bind:class="{ mobile: isMobile() }"
        >
          Nouveau service
        </button>
        <button
          v-on:click="togglePopupTypes()"
          class="button is-info is-medium addBtn types"
          v-bind:class="{ mobile: isMobile() }"
        >
          Catégories
        </button>
      </div>
      <div class="search-w">
        <b-field label="Recherche" label-position="on-border">
          <b-input size="is-large" type="text" v-model="searchTerm"></b-input>
        </b-field>
      </div>
      <div class="spa-item-w" v-bind:class="{ mobile: isMobile() }">
        <div v-for="item of spaList" :key="item.name">
          <div
            v-if="checkSearch(item.name)"
            class="item-w spa-item has-text-left"
          >
            <div class="name-w">
              <p
                v-if="!item.editingName"
                @click="nameEditMode(item)"
                class="title name"
              >
                {{ item.name }}
              </p>

              <div v-else class="name-form-w">
                <b-field label="Nom" label-position="on-border">
                  <b-input
                    size="is-large"
                    type="text"
                    v-model="item.newName"
                  ></b-input>
                </b-field>
                <div class="btns">
                  <button
                    v-on:click="validateName(item)"
                    class="button is-success"
                  >
                    Valider
                  </button>
                  <button
                    v-on:click="nameEditMode(item)"
                    class="button is-danger"
                  >
                    Annuler
                  </button>
                </div>
              </div>
            </div>

            <div class="price-w">
              <p
                v-if="!item.editingPrice"
                @click="priceEditMode(item)"
                class="title price"
              >
                {{ item.price }} dh
              </p>

              <div v-else class="price-form-w">
                <b-field label="Prix" label-position="on-border">
                  <b-input
                    size="is-large"
                    type="number"
                    v-model="item.newPrice"
                  ></b-input>
                </b-field>
                <div class="btns">
                  <button
                    v-on:click="validatePrice(item)"
                    class="button is-success"
                  >
                    Valider
                  </button>
                  <button
                    v-on:click="priceEditMode(item)"
                    class="button is-danger"
                  >
                    Annuler
                  </button>
                </div>
              </div>
            </div>

            <div class="desc-w">
              <p
                v-if="!item.editingDesc"
                @click="descEditMode(item)"
                class="title desc"
              >
                {{ item.desc }}
              </p>

              <div v-else class="desc-form-w">
                <b-field label="Description" label-position="on-border">
                  <b-input type="textarea" v-model="item.newDesc"></b-input>
                </b-field>
                <div class="btns">
                  <button
                    v-on:click="validateDesc(item)"
                    class="button is-success"
                  >
                    Valider
                  </button>
                  <button
                    v-on:click="descEditMode(item)"
                    class="button is-danger"
                  >
                    Annuler
                  </button>
                </div>
              </div>
            </div>

            <b-field label="Catégorie" label-position="on-border">
              <b-select
                placeholder="Choisir une categorie"
                v-model="item.type"
                @input="typeChange($event, item)"
              >
                <option
                  v-for="type in typesList.servicesTypes"
                  :value="type"
                  :key="type"
                >
                  {{ type }}
                </option>
              </b-select>
            </b-field>

            <button
              v-if="
                !item.editingName && !item.editingDesc && !item.editingPrice
              "
              class="button deleteBtn is-danger"
              @click="deleteService(item.id)"
            >
              SUPPRIMER
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from "../firebase";
export default {
  data() {
    return {
      searchTerm: "",
      addNewService: false,
      addNewType: false,
      newTypeValue: "",
      showNewTypeInput: false,
      newService: {
        name: "",
        price: 0,
        desc: "",
      },
      descModif: true,
      spaList: [],
      typesList: [],
    };
  },
  computed: {
    validateForm() {
      if (
        this.newService.name != "" &&
        this.newService.price != 0 &&
        this.newService.desc != ""
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  firestore() {
    return {
      spaList: db.collection("spaServices"),
      typesList: db.collection("infos").doc("spa"),
    };
  },
  methods: {
    typeChange(value, item) {
      console.log(value);
      console.log(item.uid);
      this.validateType(item, value);
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    checkSearch(name) {
      return name
        .toUpperCase()
        .replaceAll(" ", "")
        .replaceAll("É", "E")
        .includes(
          this.searchTerm.toUpperCase().replaceAll(" ", "").replaceAll("É", "E")
        );
    },
    deleteService(id) {
      db.doc(`spaServices/${id}`)
        .delete()
        .then(function (doc) {
          console.log(doc, "deleted :" + id);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    sendNewService() {
      if (this.validateForm) {
        console.log(
          this.newService.name,
          this.newService.price,
          this.newService.desc
        );
        const ref = db.collection("spaServices").doc();
        ref
          .set({
            name: this.newService.name,
            price: this.newService.price,
            desc: this.newService.desc,
            editingDesc: false,
            editingName: false,
            editingPrice: false,
            newDesc: "",
            newName: "",
            newPrice: "",
            uid: ref.id,
          })
          .then((response) => {
            console.log({ response });
            this.addNewService = false;
            this.newService.name = "";
            this.newService.price = 0;
            this.newService.desc = "";
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    toggleNewTypeInput() {
      if (this.showNewTypeInput) {
        this.showNewTypeInput = false;
        this.newTypeValue = "";
      } else {
        this.showNewTypeInput = true;
      }
    },
    togglePopup() {
      if (this.addNewService) {
        this.addNewService = false;
      } else {
        this.addNewService = true;
      }
    },
    togglePopupTypes() {
      if (this.addNewType) {
        this.addNewType = false;
      } else {
        this.addNewType = true;
      }
    },
    descEditMode(item) {
      console.log(item);
      if (item.editingDesc) {
        item.editingDesc = false;
        item.newDesc = "";
      } else {
        item.editingDesc = true;
        item.newDesc = item.desc;
      }
    },
    priceEditMode(item) {
      console.log(item);
      if (item.editingPrice) {
        item.editingPrice = false;
      } else {
        item.editingPrice = true;
        item.newPrice = item.price;
      }
    },
    nameEditMode(item) {
      console.log(item);
      if (item.editingName) {
        item.editingName = false;
      } else {
        item.editingName = true;
        item.newName = item.name;
      }
    },
    validateDesc(item) {
      console.log(item.newDesc);
      db.doc(`spaServices/${item.uid}`)
        .update({ desc: item.newDesc })
        .then(function (doc) {
          console.log(doc);
          item.editingDesc = false;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    validatePrice(item) {
      console.log(item.newPrice);
      db.doc(`spaServices/${item.uid}`)
        .update({ price: parseInt(item.newPrice) })
        .then(function (doc) {
          console.log(doc);
          item.editingPrice = false;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    validateName(item) {
      console.log(item.newName);
      db.doc(`spaServices/${item.uid}`)
        .update({ name: item.newName })
        .then(function (doc) {
          console.log(doc);
          item.editingName = false;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    validateType(item, type) {
      db.doc(`spaServices/${item.uid}`)
        .update({ type: type })
        .then(function (doc) {
          console.log(doc);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    deleteType(type) {
      console.log(type);
      db.doc("infos/spa")
        .get()
        .then((doc) => {
          const arr = doc.data().servicesTypes;
          console.log(arr);
          arr.splice(arr.indexOf(type), 1);
          console.log(arr);
          db.doc("infos/spa")
            .update({
              servicesTypes: arr,
            })
            .then(function (doc) {
              console.log(doc, "success");
            })
            .catch(function (error) {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    pushType() {
      console.log(this.newTypeValue);
      db.doc("infos/spa")
        .get()
        .then((doc) => {
          const beforeArr = doc.data().servicesTypes;
          console.log(beforeArr);
          const newArr = [...beforeArr, this.newTypeValue];
          console.log(newArr);
          db.doc("infos/spa")
            .update({
              servicesTypes: newArr,
            })
            .then(doc => {
              console.log(doc, "success");
              this.toggleNewTypeInput();
            })
            .catch(error => {
              console.log(error);
            });
        })
        .catch(error => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>

.danger-text {
  margin-bottom: 0px;
  margin-top: 40px;
  color: red;
  font-weight: bold;
  text-transform: uppercase;
}

.danger-text span {
  font-size: 2rem;
}

.search-w {
  padding: 0px 1.5rem;
  height: 60px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.type-w {
  display: flex;
  margin: auto;
  justify-content: space-between;
  align-items: center;
  width: 450px;
  max-width: 90vw;
  margin-top: 30px;
}

.type-w:first-child {
  margin-top: 0px;
}
.type-w:last-child {
  margin-bottom: 50px;
}

.type-w p.subtitle {
  margin: 0px;
  color: black;
  font-weight: bold;
  text-align: left;
  font-size: 1.5rem;
}
.type-w .closeBtn {
  margin: 0px !important;
}

#typesPopup {
  z-index: 1000;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.789);
}
#typesPopup .popup-card {
  position: relative;
  padding: 90px;
  padding-top: 120px;
  background-color: white;
  border-radius: 20px;
  width: 1000px;
  max-width: 90vw;
}

#typesPopup.mobile .popup-card {
  position: relative;
  padding: 20px;
  padding-top: 120px;
  background-color: white;
  border-radius: 20px;
  width: 1000px;
  max-width: 90vw;
}

#typesPopup .popup-card .title {
  position: absolute;
  z-index: 999;
  top: 40px;
  left: 0px;
  right: 0px;
}

#typesPopup .popup-card .validateBtn {
  margin-bottom: 0px;
  margin-top: 60px;
  border-radius: 9px;
}

#typesPopup .popup-card .closeBtn {
  margin-bottom: 0px;
  margin-top: 20px;
  border-radius: 9px;
}

#newServicePopup {
  z-index: 1000;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.789);
}
#newServicePopup .popup-card {
  position: relative;
  padding: 90px;
  padding-top: 120px;
  background-color: white;
  border-radius: 20px;
  width: 1000px;
  max-width: 90vw;
}

#newServicePopup.mobile .popup-card {
  position: relative;
  padding: 20px;
  padding-top: 120px;
  background-color: white;
  border-radius: 20px;
  width: 1000px;
  max-width: 90vw;
}

#newServicePopup .popup-card .title {
  position: absolute;
  z-index: 999;
  top: 40px;
  left: 0px;
  right: 0px;
}

#newServicePopup .popup-card .validateBtn {
  margin-bottom: 0px;
  margin-top: 60px;
  border-radius: 9px;
}

#newServicePopup .popup-card .closeBtn {
  margin-bottom: 0px;
  margin-top: 20px;
  border-radius: 9px;
}

.master-w .hero-body {
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
}
.title-w {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  margin: 0px 1.5rem;
  margin-bottom: 40px;
}
.title-w.mobile {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60px;
  margin: 0px 1.5rem;
  margin-bottom: 40px;
}
.title-w p.title {
  margin: 0px;
}
.title-w .addBtn {
  position: absolute;
  right: 0px;
  top: 0px;
  height: 60px;
  border-radius: 8px;
}
.title-w .addBtn.types {
  position: absolute;
  left: 0px;
  top: 0px;
  height: 60px;
  border-radius: 8px;
}
.title-w .addBtn.mobile {
  position: initial;
  right: 0px;
  top: 0px;
  height: 40px;
  border-radius: 9px;
  width: 100%;
  margin-top: 0px;
  margin-bottom: 5px;
}

.spa-item-w {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: calc(100vh - 70px - 3rem - 60px - 40px - 90px);
  padding: 1.5rem 1.5rem;
}

.spa-item-w.mobile {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: calc(100vh - 70px - 3rem - 60px - 40px - 90px);
  padding: 1.5rem 1.5rem;
}

.spa-item-w .item-w {
  margin-bottom: 20px;
}
.item-w {
  background-color: #ffffff;
  border-radius: 20px;
  padding: 20px;
}
.spa-item {
  position: relative;
  box-shadow: 0px 6px 26px rgba(0, 0, 0, 0.1);
}
.spa-item .title.price {
  font-size: 2rem;
}
.spa-item .title.desc {
  font-size: 1rem;
  margin-bottom: 0px;
}
button.button.deleteBtn {
  position: absolute;
  top: 20px;
  right: 20px;
  border-radius: 8px;
}

.spa-item-w.mobile .button.deleteBtn {
  position: absolute;
  top: 20px;
  right: 20px;
  left: 20px;
  width: calc(100% - 40px);
  border-radius: 8px;
}

.desc-w {
  margin-bottom: 30px;
}

.desc-form-w {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}
.desc-form-w :first-child {
  flex: 1;
  margin-bottom: 0px;
}
.desc-form-w .btns {
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.desc-form-w .btns button.button {
  flex: 1;
  height: 100%;
}

.price-w {
  margin-bottom: 20px;
}
.price-form-w {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}
.price-form-w :first-child {
  flex: 1;
  margin-bottom: 0px;
}
.price-form-w .btns {
  padding-left: 20px;
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.price-form-w .btns button.button {
  flex: 1;
  height: 100%;
}

.name-w {
  margin-bottom: 20px;
}
.name-w .title.name {
  max-width: calc(100% - 140px);
}

.spa-item-w.mobile .name-w .title.name {
  margin-top: 60px;
  max-width: calc(100%);
}
.name-form-w {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}
.name-form-w :first-child {
  flex: 1;
  margin-bottom: 0px;
}
.name-form-w .btns {
  padding-left: 20px;
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.name-form-w .btns button.button {
  flex: 1;
  height: 100%;
}
</style>